import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from '../organon/organonSlice';
import { createScore, putScore, selectScore, fetchScoreById, duplicateScore } from './scoreSlice';
import { moveLineCancel, moveLineConfirm, moveLinePreview,
  selectConnectors, selectLine, selectLineDepth } from './linesSlice';
import { expandLine, selectActiveAttachment, selectLineExpanded, toggleLine, INTERFACE_MODES, openEditor, openPlayer  } from './interfaceSlice';
import { translate as t } from '../../utils/translate';
import LineActant from './Line/Actant';
import LineActions from './Line/Actions';
import LineAdresse from './Line/Adresse';
import LineAlternative from './Line/Alternative';
import LineAspect from './Line/Aspect';
import LineAttachments from './Line/Attachments';
import LineBoucle from './Line/Boucle';
import LineConnectors from './LineConnectors';
import LineCondition from './Line/Condition';
import LineDestination from './Line/Destination';
import LineTerme from './Line/Terme';
import LineIndications from './Line/Indications';
import LineEditor from './LineEditor';
import { DRAG_ITEM_TYPES, FETCH_STATUSES, LINE_TYPES, LINK_MODES, UNDOABLE_EDITOR_UNDO, UNDOABLE_EDITOR_REDO } from './constants';
import { useDrag, useDrop } from 'react-dnd';
import { makeConnectionPointId } from '../../utils/lineconnector';
import Attachment from './Line/Attachment';
import { ScoreLevelSlider } from './LevelSlider';
import { Player } from './Player';
import { shouldShowTag } from './Line/utils';

export function ConnectedLine ({ id, index, isLastLine, canEdit }) {
  const line = useSelector(state => selectLine(state, id)),
        targetLine = useSelector(state => selectLine(state, line.link.line));

  return <details
    className="axis-row--wrapper"
    key={ line.id }
    open={ false }
    data-id={ line.id }
    id={ line.id }
    data-is-last-line={ isLastLine }
  >
  <summary className="axis-row">
    <section className="axis-row--background-wrapper">
      <LineCondition condition={ line.condition } contingent={ line.contingent } necessary_when={ line.necessary_when } />
      <section className="line__body">
        <span className="line__title" id={ makeConnectionPointId(line.id) }>
          { line.link.mode }:
          { targetLine.title }
        </span>
      </section>
    </section>
    { canEdit && <LineActions line={ line } /> }
  </summary>
  </details>;
}

/**
 * Linked lines are always expanded.
 * 
 * Sublines which are linked them selves are rendered as connected lines.
 * 
 * @param {*} param0 
 * @returns 
 */
export function LinkedLine ({ id, index, isLastLine, canEdit, forceOpen, stopAt }) {
  const dispatch = useDispatch(),
        line = useSelector(state => selectLine(state, id)),
        targetLine = useSelector(state => selectLine(state, line.link.line)),
        expanded = useSelector(state => selectLineExpanded(state, id)) || forceOpen;

  return <details
    className="axis-row--wrapper"
    key={ line.id }
    open={ expanded }
    data-id={ line.id }
    id={ line.id }
    data-module={ line.module_ }
    data-is-last-line={ isLastLine }
    onToggle={ (e) => { e.stopPropagation(); if (e.target.open != expanded) { dispatch(toggleLine({ id: line.id })) } } }
  >
  <summary className="axis-row">
    <section className="axis-row--background-wrapper">
      <LineCondition condition={ line.condition } contingent={ line.contingent } necessary_when={ line.necessary_when } />
      <section className="line__body" onClick={ (forceOpen) ? (e) => { e.preventDefault(); } : null }>
        <span className="line__title">
          { line.link.mode }: { targetLine.title }
        </span>
      </section>
      { line.terme && <LineTerme terme={ line.terme } /> }
    </section>
    { canEdit && <LineActions line={ line } /> }
  </summary>
  { targetLine.sublines.length > 0 && stopAt.indexOf(id) < 0 && <SublinesOf id={ targetLine.id } canEdit={ false } forceOpen={ true } stopAt={ stopAt.concat([ id ]) } /> }
  </details>;
}

export function Line ({ id, index, isMainLine, isLastLine, canEdit, forceOpen, stopAt=[] }) {
  const dispatch = useDispatch(),
        dndRef = useRef(null),
        line = useSelector(state => selectLine(state, id)),
        expanded = useSelector(state => selectLineExpanded(state, id)) || forceOpen;

  const [ { isDragging }, dragRef ] = useDrag(() => ({
    type: DRAG_ITEM_TYPES.LINE,
    item: { 
      lineId: line.id,
      originalIndex: index,
      originalParentId: line.parentId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
    canDrag: (monitor) => canEdit,
    isDragging: (monitor) => (monitor.getItem() && monitor.getItem().lineId === line.id),
    end: (item, monitor) => {
      const { lineId, originalIndex, originalParentId } = item,
            didDrop = monitor.didDrop();
      if (!didDrop) {
        dispatch(moveLineCancel({ lineId, originalIndex, originalParentId }));
      }
      else {
        dispatch(moveLineConfirm());
      }
    }
  }), [ line.id, index, line.parentId ])

  const [ { isOver }, dropRef ] = useDrop(() => ({
    accept: DRAG_ITEM_TYPES.LINE,
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver({ shallow: true })
      }
    },
    hover: ({ lineId }, monitor) => {
      if (
        line.parentId 
        && lineId !== line.id 
        && dndRef.current 
        && monitor.isOver({ shallow: true })
      ) {
        if (!expanded && line.sublines.length > 0 && (monitor.getClientOffset().x - dndRef.current?.getBoundingClientRect().left) > 100) {
          dispatch(expandLine({ id: line.id }));
        }
        else {
          const asChild = (line.sublines.length == 0
            && (monitor.getClientOffset().x - dndRef.current?.getBoundingClientRect().left) > 100);
          dispatch(moveLinePreview({ lineId: lineId, insertionPointId: line.id, asChild: asChild }));
        }
      }
    },
  }), [ line.id ])

  dragRef(dropRef(dndRef));

  return <details
      className="axis-row--wrapper"
      key={ line.id }
      open={ expanded }
      onToggle={ (e) => {
        e.stopPropagation();
        if (e.target.open != expanded) { dispatch(toggleLine({ id: line.id })) } } }
      ref={ line.parentId && dndRef }
      data-dnd-dragging={ line.parentId && isDragging }
      data-dnd-is-over={ line.parentId && isOver }
      data-module={ line.module_ }
      data-contingent={ !!line.contingent }
      data-id={ line.id }
      data-alternative={ !!line.alternative }
      id={ line.id }
      data-has-sublines={ (line.sublines.length > 0) ? true : null }
      data-is-mainline={ isMainLine }
      data-is-last-line={ isLastLine }
    >
    <summary className="axis-row">
      <section className="axis-row--background-wrapper">
        <LineCondition condition={ line.condition } contingent={ line.contingent } necessary_when={ line.necessary_when } />
        
        { line.actant && <LineActant actant={ line.actant } /> }
        
        <section className="line__body" onClick={ (forceOpen) ? (e) => { e.preventDefault(); } : null }>
          <span className="line__title" id={ makeConnectionPointId(line.id) }>
            { line.title }
          </span>

          <LineAspect aspect={ line.aspect } />
          
          { ( line.indications || line.commandement || line.code ) && <LineIndications indications={ line.indications} commandement={ line.commandement } destination={ line.destination } code={ line.code } /> }
        
          { line.attachments.length > 0 && <LineAttachments attachmentIds={ line.attachments } /> }
        
        </section>


        { line.adresse && <>
          <LineAdresse adresse={ line.adresse } />
          { line.destination && <LineDestination destination={ line.destination } />}
        </> }

        { line.boucle && <LineBoucle boucle={ line.boucle } />}

        { line.terme && <LineTerme terme={ line.terme } /> }
        
      </section>
      { canEdit && <LineActions line={ line } /> }
    </summary>  
    { line.sublines.length > 0 && <SublinesOf id={ line.id } canEdit={ canEdit } forceOpen={ forceOpen } stopAt={ stopAt } /> }
  </details>;
}

/*
 Extracted to avoid unnecessary re-renders
*/
export function SublinesOf ({ id, canEdit, forceOpen, stopAt }) {
  const line = useSelector(state => selectLine(state, id)),
        // tag and transition are consistent among siblings
        // extract the first subline and take its transition and tag
        firstSubline = useSelector((state) => selectLine(state, line.sublines[0])),
        tag = firstSubline?.tag,
        transition = firstSubline?.transition;
  
  if (line.sublines) {
    return <section className="sublines">
      <section className="sublines__metadata__wrapper">
        <section className="sublines__metadata">
          { tag && shouldShowTag(line) && <span className="tag" data-tag={ tag } /> }
          { transition && <span className="transition" label={ t(transition) } data-transition={ transition } /> }
          { line.alternative && <LineAlternative alternative={ line.alternative } /> }
        </section>
      </section>
      { line.sublines.map((id, index) => <LineOrLinkedLine key={ id } id={id} index={index} isLastLine={ index == line.sublines.length - 1 } canEdit={ canEdit } forceOpen={ forceOpen } stopAt={ stopAt } />) }
    </section>;
  }
  else {
    return <></>;
  }
}

function LineOrLinkedLine ({ id, index, isLastLine, canEdit, forceOpen, stopAt }) {
  const line = useSelector(state => selectLine(state, id))

  if (line.type == LINE_TYPES.LINK) {
    if (line.link.mode == LINK_MODES.GOTO) {
      // Connected line can not have sublines
      return <ConnectedLine index={ index } id={ id } isLastLine={isLastLine} canEdit={ canEdit } />;
    }
    else {
      return <LinkedLine index={ index } id={ id } isLastLine={isLastLine} canEdit={ canEdit } forceOpen={ forceOpen } stopAt={ stopAt } />;
    }
  }
  else {
    return <Line index={ index } id={ id } isLastLine={isLastLine} canEdit={ canEdit } forceOpen={ forceOpen } stopAt={ stopAt }  />;
  }
}

export function UndoRedoButtons () {
  const dispatch = useDispatch(),
        hasHistory = useSelector(state => state.editor.past.length > 0),
        hasFuture = useSelector(state => state.editor.future.length > 0);

  return <>
    <button onClick={ () => dispatch({ type: UNDOABLE_EDITOR_UNDO })} disabled={ !hasHistory }>undo</button>
    <button onClick={ () => dispatch({ type: UNDOABLE_EDITOR_REDO }) } disabled={ !hasFuture }>redo</button>
  </>
}

function Spacer () {
  return <span style={ { width: '5ch',  display: 'inline-block' } }></span>
}

export function Score () {
  const dispatch = useDispatch(),
        score = useSelector(selectScore),
        interfaceMode = useSelector(state => state.interface.mode),
        connectors = useSelector(selectConnectors),
        fetch_status = useSelector(state => state.editor.present.score.fetch.status),
        put_status = useSelector(state => state.editor.present.score.put.status),
        put_error = useSelector(state => state.editor.present.score.put.error), 
        depth = useSelector(state => selectLineDepth(state, score?.mainline)),
        activeAttachment = useSelector(selectActiveAttachment),
        current_user = useSelector(selectCurrentUser);

  // useEffect(() => {
  //   if (status === FETCH_STATUSES.IDLE)  {
  //     dispatch(fetchScoreById(1));
  //   }
  // }, [status, dispatch]);

  useEffect(() => {
    if (fetch_status === FETCH_STATUSES.IDLE
      && score === null && document.location.pathname.startsWith('/score/'))  {
      // hack, hack, let's put some tape
      const patt = /^\/score\/(\d+)/,
            match = patt.exec(document.location.pathname);

      if (match) {
        const scoreId = match[1];
        dispatch(fetchScoreById(scoreId));
      }
      // console.log(scoreId, document.location.pathname); 
      // dispatch(fetchScoreList());
    }
  }, [fetch_status]);

  if (fetch_status === FETCH_STATUSES.IDLE) {
    return <></>
  }
  else if (fetch_status === FETCH_STATUSES.PENDING) {
    return <>Loading score</>;
  } else if (fetch_status === FETCH_STATUSES.FAILED) {
    return <>Loading score failed</>;
  }
  else {
    return <>
      { interfaceMode == INTERFACE_MODES.EDITOR && <>
        { score.is_editable && <>
          <Spacer />
          <button onClick={ () => dispatch(putScore())} disabled={ (put_status === FETCH_STATUSES.PENDING )}>{ t('Enregistrer') }</button>
        </> }
        <Spacer />
        <button onClick={ () => dispatch(openPlayer())}>Perform score (player)</button>
        <Spacer />
        { current_user && <button onClick= { () => dispatch(duplicateScore()) }>Duplicate score</button> }
        <UndoRedoButtons />
        { put_status === FETCH_STATUSES.PENDING && <span className="status-message">Saving score...</span> }
        { put_status === FETCH_STATUSES.FAILED && <span className="status-message error">{ put_error }</span> }
        { put_status === FETCH_STATUSES.FULFILLED && <span className="status-message">Changes were saved</span> }
        <h1 style={ { paddingLeft: '1rem' }}>{ score.title }</h1>
        { activeAttachment && <Attachment attachment={ activeAttachment } />}
        <LineEditor line={ score.mainline } data-read-only={ !score.is_editable } />
        <ScoreLevelSlider levels={ depth } />
        <div className="score__container">
          <div className="score__lines__wrapper">
            <Line index={ 0 } id={ score.mainline } isMainLine={ true } canEdit={ score.is_editable } />
            <LineConnectors connectors={ connectors } />
          </div>
        </div></>
      }
      {
        interfaceMode == INTERFACE_MODES.PLAYER && <>
          <button onClick={ () => dispatch(openEditor())}>Edit score (editor)</button>    
          <h1 style={ { paddingLeft: '1rem' }}>{ score.title }</h1>
          <Player />
        </>
      }
    </>;
  }

}