import React from 'react';
import { translate as t } from '../../../utils/translate';
import HelpShortcut from '../../../utils/HelpShortcut';
import { FETCH_STATUSES } from '../constants';
import { showAttachment } from '../interfaceSlice';
import { connect } from 'react-redux';

/**
 * @TODO still feels complex and a bit fragile.
 */

/**
 * onChange callback will be called twice.
 * Once when a file is selected, a second time when it is read
 * and a file url has been made.
 */

/**
 * Attachment field. Has three states:
 * 1 - populated, show a clear button to remove the attachments
 * 2 - empty, upload mode: show a file select field
 * 3 - empty, url mode: show a text field to insert a url
 */

/**
 * From the original version
 * 
  function () {
    var model = {}, callback;
    uploadQueue.add((function upload (accept, reject) {
      var formData = new FormData();
      formData.append('title', entry.title);
      formData.append('attachment', entry.file);

      $.ajax({
          type: "POST",
          url: '/api/attachments/',
          enctype: 'multipart/form-data',
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          success: _.bind(function (data) {
              model.set(data);
              if (callback) {
                callback();
              }
              accept();
          }, this),
          error: reject,
          dataType: 'json'
      });
    }));
  }
*/

const FIELD_MODES = {
  upload: 0,
  url: 1
}

/**
 * Component renders a single attachment field. Can have three states:
 * - There is an attachment. It is rendered.
 * - There is no attachment, the field is in upload mode (default), a file field is rendered
 * - There is no attachment, the field is in url mode, a text field is rendered.
 */
class AttachmentField extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      id: props.attachment.id,
      mode: this.deriveMode(props.attachment.state, props.attachment.type, props.attachment.url),
      file: null, // Holds a reference to the file if it's in upload mode,
      filename: null, // Store filename in uploadmode
      title: props.attachment?.title,
      url: props.attachment.url,
      type: props.attachment.type
    }
  }

  deriveMode(state, type, url) {
    // Can't an attachment not only be idle on loading?
    if (state == FETCH_STATUSES.IDLE) {
      // When there is a url but it starts with data
      // it as a pending upload
      if (type && url && url.startsWith('data:')) {
        return FIELD_MODES.upload;
      } 
      else if (url) {
        return FIELD_MODES.url;
      }
      else {
        // Empty
        return FIELD_MODES.upload
      }
    }
    else {
      // Type holds the mimetype of the attachment
      // if it has one it is a file, otherwise a link
      if (type) {
        return FIELD_MODES.upload;
      }
      else {
        return FIELD_MODES.url;
      }
    }
  }

  fileInputRef = React.createRef()

  // handleFile (files) {
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  
  //     if (!file.type.startsWith("image/")) {
  //       continue;
  //     }
  
  //     const img = document.createElement("img");
  //     img.classList.add("obj");
  //     img.file = file;
  //     preview.appendChild(img); // Assuming that "preview" is the div output where the content will be displayed.
  
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       img.src = e.target.result;
  //     };
  //     reader.readAsDataURL(file);
  // }
  
  deriveTitleFromFilename (filename) {
    if (filename) {
      const lastDot = filename.lastIndexOf('.');
      if (lastDot > 0) {
        return filename.substring(0, lastDot);
      }
    }
    return filename;
  }

  onFileInputSelect () {
    if (this.fileInputRef.current.files) {
      // @FIXME Changes are propagated twice:
      // - when file is selected
      // - when it is read
      // Make sure this is desired?
      // Probably done to give immediate feedback to user.
      this.setState({
        file: this.fileInputRef.current.files[0],
        type: this.fileInputRef.current.files[0].type,
        filename: this.fileInputRef.current.files[0].name,
        title: (!this.state.title) ? this.deriveTitleFromFilename(this.fileInputRef.current.files[0].name) : this.state.title,
      }, this.propagateChange);

      const fileReader = new FileReader();
      fileReader.addEventListener("load", (e) => {
        this.setState({
          url: fileReader.result,
        }, this.propagateChange);
      });
      fileReader.readAsDataURL(this.fileInputRef.current.files[0]);
    }
  }

  onFileInputClear () {
    this.setState({
      file: null,
      type: null,
      url: null,
      title: (this.state.file && this.state.title == this.deriveTitleFromFilename(this.state.filename)) ? '' : this.state.title,
    }, this.propagateChange);
    this.fileInputRef.current.value = '';
  }

  /**
   * Derive / calculate public state based on private state.
   * @returns Attachment {}
   */
  derivePublicState () {
    return {
      id: this.state.id,
      title: this.state.title,
      type: this.state.type,
      url: this.state.url,
      state: (this.state.mode == FIELD_MODES.upload) ? FETCH_STATUSES.IDLE : null,
      filename: (this.state.mode == FIELD_MODES.upload) ? this.state.filename : null
    };
  }

  propagateChange() {
    this.props.onChange(this.derivePublicState());
  }

  render () {
    const { attachment, onRemove } = this.props;
    let attachmentBody;

    if (attachment.attachment) {
      attachmentBody = ""
    }
    else if (this.state.mode == FIELD_MODES.upload) {
      // Construct upload form
      attachmentBody = <label>
        <span className="label-text"></span>
        <input type="file" name="attachment--file" className="inline" hidden={ (this.state.file || this.state.url) } ref={ this.fileInputRef } onChange={ () => this.onFileInputSelect() } />
        {(this.state.file || this.state.url) && <span className="inline">
          <button className="inline" onClick={ () => this.onFileInputClear() }>X</button>
        </span>}
        <button type="button" className="inline" onClick={ () => this.setState({ 'mode': FIELD_MODES.url }) }>{ t('Mode URL') }</button>
      </label>
    }
    else {
      // Show form for attachment URL
      attachmentBody = <label className="inline">
        <span className="label-text"></span>
        <input type="text" name="attachment--url" placeholder={ t('URL') } />
        <button type="button" className="inline" onClick={ () => this.setState({ 'mode': FIELD_MODES.upload }) }>{ t('Mode Téléversement') }</button>
      </label>
    }
    return <>
      <label>
        <HelpShortcut target="piece-jointe">{ t('Pièce-jointe') }</HelpShortcut>
        <input type="text" name="attachment--title" placeholder={ t('Titre') } value={ this.state.title } onChange={ (e) => this.setState({ 'title': e.target.value }, this.propagateChange) } />
      </label>
      { attachmentBody }
      <label>
        <span className="label-text"></span>
        {(this.state.file || this.state.url) && <button type="button" className="inline" onClick={ (e) => { 
          // Trigger event to show attachment in the interface
          // This needs to move to a place that is also rendered for existing attachments.
          e.preventDefault();
          if (this.props.showAttachment) {
            this.props.showAttachment(this.derivePublicState());
          }
          } }>
            { t('show') }
            {/* { this.state.filename || this.state.title || t('previously uploaded file') } */}
        </button>}
      </label>
      <label>
        <span className="label-text"></span>
        <button type="button" className="inline" onClick={ () => onRemove() }>{ t('supprimer') }</button> 
      </label>
    </>  
  }
}

const mapDispatchToProps = (dispatch) => ({
  showAttachment: (id) => dispatch(showAttachment(id))
})

export default connect(null, mapDispatchToProps)(AttachmentField);