import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchScoreById } from './scoreSlice';
import { FETCH_STATUSES, FETCH_STATUS_FAILED, FETCH_STATUS_FULFILLED, FETCH_STATUS_PENDING } from './constants'
import attachmentAPI from './attachmentApi';
import uuidv4 from '../../utils/uuid';

/**
 * 
 *  attachment can be:

    @FIXME simplify? Explicitely add mode to the attachment object?
    Possibly in parsing of API

    Attachment data lives in two places:
    - Files are stored on the attachment API
    - Attachment metadata lives within the score meta array or within the lines

    In two modes:
    - file mode → if synced: mime, if unsynced: mime & *data*url
    - url → synced & no mime, unsynced & no file & url

    Have two states
    - synced
    - unsynced

    If a attachment is unsynced but the file is set the attachment is in file mode.
    Show the file loaded indication.

    If an attachment is synced, type should be set. If it's not, it's in url mode. Show URL.

 */

/**
 * Attachment shape
 * {
 *   id: string,
 *   url: string, # Location of the attachment
 *   title: string, # Title / name of the attachment
 *   type: string, # Mimetype
 *   file: null | File # Blob, only set on creation of attachment
 *   state: 'idle' | 'pending' | 'failed' | 'fulfilled'
 * }
 */

const attachmentsAdapter = createEntityAdapter();

const adapterSelectors = attachmentsAdapter.getSelectors();

const reducerHelpers = {}

const localSelectors = {}

const adaptedSelector = (selector) => (state, ...args) => selector(state.attachments, ...args)

export const uploadAttachment = createAsyncThunk(
  'attachments/uploadAttachment',
  async (attachmentId, { getState, dispatch, rejectWithValue }) => {
    const attachment = selectAttachment(getState(), attachmentId);
    dispatch(updateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_PENDING } }));
    return attachmentAPI.uploadAttachment(attachment)
      .then((data) => {
        const newAttachment = {
          id: data.id,
          title: data.title,
          url: data.attachment,
          attachment: data.attachment,
          type: attachment.type,
          state: FETCH_STATUS_FULFILLED
        };
        
        // Insert new attachment, replace old entities
        dispatch(replaceAttachment({
          attachmentId: attachmentId,
          newAttachment: newAttachment
        }));
        
        return { 
          id: newAttachment.id,
          attachment: newAttachment,
          oldId: attachmentId,
        };
      })
      .catch((e) => {
        dispatch(updateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_FAILED } }));
        return rejectWithValue({
          id: attachmentId,
          error: e.toString()
        });
      });
  }
)

/**
 * Duplicates attachment in local state and returns id of copy.
 * Registers the duplicate to be duplicated on remote in: attachmentsToDuplicateOnRemote
 */
export const duplicateAttachment = createAsyncThunk(
  'attachment/duplicateAttachment',
  async (attachmentId, { getState, dispatch, rejectWithValue  }) => {
    const attachment = selectAttachment(getState(), attachmentId),
          clone = { ...attachment, id: uuidv4() };
          
    dispatch(addAttachment(clone));
    dispatch(queueToDuplicateOnRemote({ remoteId: attachment.id, attachmentId: clone.id }));

    return clone.id;
  }
)


export const duplicateAttachmentOnRemote = createAsyncThunk(
  'attachment/duplicateAttachmentOnRemote',
  /**
   * Sends a call to the remote to duplicate an attachment which is
   * already duplicated in the local state.
   * 
   * Takes a dict as payload with two entries
   * - attachmentId id of the duplicated attachment
   * - remoteId id on the remote of which the local attachment is a duplicate
   * 
   * @param {{ attachmentId: string, remoteId: string }} param0 
   * @returns 
   */
  async ({ attachmentId, remoteId }, { getState, dispatch, rejectWithValue }) => {
    // dispatch(duplicateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_PENDING } }));
    const attachment = selectAttachment(getState(), attachmentId);

    return attachmentAPI.duplicateAttachment(remoteId)
      .then((data) => {
        const newAttachment = {
          id: data.id,
          title: data.title,
          url: data.attachment,
          attachment: data.attachment,
          type: attachment.type, // Type is not stored on the attachment API.
          state: FETCH_STATUS_FULFILLED
        };
        
        // Replace the attachment in the local state
        // linesSlice should also observe this action
        dispatch(replaceAttachment({
          attachmentId,
          newAttachment
        }));
        // Mark as duplicated and remove from queue
        dispatch(markDuplicatedOnRemote({ attachmentId }));
        return newAttachment.id;
      })
      .catch((e) => {
        dispatch(duplicateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_FAILED } }));
        return rejectWithValue({
          id: attachmentId,
          error: e.toString()
        });
      });
  }
)


// Deletes attachment on remote. Does not touch local store.
export const deleteAttachmentOnRemote = createAsyncThunk(
  'attachment/deleteAttachment',
  async (attachmentId, { rejectWithValue }) => {
    return attachmentAPI.deleteAttachment(attachmentId)
      .then(() => attachmentId)
      .catch((e) => {
        return rejectWithValue({
          id: attachmentId,
          error: e.toString()
        });
      });
  }
)

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: attachmentsAdapter.getInitialState({
    idsToDeleteOnRemote: [], // Track ids of entitities that need to be removed on the remote
    attachmentsToDuplicateOnRemote: {} // Mapping of attachments to duplicate: { str:attachmentId: str:remoteId }
  }),
  reducers: {
    addAttachment: (state, action) => attachmentsAdapter.addOne(state, action.payload),
    removeAttachment: (state, action) => {
      const attachment = adapterSelectors.selectById(state, action.payload);
      if (attachment.state === FETCH_STATUS_FULFILLED) {
        // Attachment exists on remote and needs to be deleted.
        state.idsToDeleteOnRemote.push(action.payload);
      }
      return attachmentsAdapter.removeOne(state, action.payload);
    },
    removeManyAttachments: (state, action) => {
      for (const attachmentId of action.payload) {
        const attachment = adapterSelectors.selectById(state, attachmentId);
        if (attachment && attachment.state === FETCH_STATUS_FULFILLED) {
          // Attachment exists on remote and needs to be deleted when changes are saved
          state.idsToDeleteOnRemote.push(attachmentId);
        }
        return attachmentsAdapter.removeOne(state, attachmentId);
      }
    },
    setAttachment: (state, action) => attachmentsAdapter.setOne(state, action.payload),
    updateAttachment: (state, action) => attachmentsAdapter.updateOne(state, action.payload),
    upsertManyAttachments: (state, action) => attachmentsAdapter.upsertMany(state, action.payload),
    replaceAttachment: (state, action) => {
      const { newAttachment, attachmentId } = action.payload;
      attachmentsAdapter.addOne(state, newAttachment);
      attachmentsAdapter.removeOne(state, attachmentId);
    },
    /**
     * Adds attachmentId to queue of attachments to be duplicated on remote.
     * 
     * Payload: { 
     *  attachmentId: string id of the attachment in the local state
     *  remoteId: string id of the attachment on remote
     * }
     * @param {} state 
     * @param {{}} action 
     */
    queueToDuplicateOnRemote: (state, action) => {
      const { attachmentId, remoteId } = action.payload;
      state.attachmentsToDuplicateOnRemote[attachmentId] = remoteId;
    },
    /**
     * Marks attachment as duplicated on remote and removes it from the queue
     * @param {*} state 
     * @param { { type: string, payload: { attachmentId: string } }}
     */
    markDuplicatedOnRemote: (state, action) => {
      const { attachmentId } = action.payload;
      delete state.attachmentsToDuplicateOnRemote[attachmentId];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScoreById.fulfilled, (state, action) => {
        // Add attachments found in lines returned by API call.
        attachmentsAdapter.addMany(state, Object.values(action.payload.lines).flatMap((line) => line.attachments.map((attachment) => ({
          ...attachment,
          state: FETCH_STATUSES.FULFILLED
        }))));
        // Add attachments which are attached to the score.
        attachmentsAdapter.addMany(state, action.payload.attachments);
      })
      .addCase(deleteAttachmentOnRemote.fulfilled, (state, action) => {
        state.idsToDeleteOnRemote = state.idsToDeleteOnRemote.filter(id => id != action.payload);
      })
  }
})

export const selectAttachment = attachmentsAdapter.getSelectors((state) => state.attachments).selectById;

export const selectManyAttachments = (state, ids) => {
  if (ids) {
    return ids.map((id) => state.attachments.entities[id]);
  }
  else {
    return [];
  }
};

export const selectAttachmentsIdsToUpload = (state) => {
  return state.attachments.ids.filter((id) => state.attachments.entities[id].state == FETCH_STATUSES.IDLE 
    || state.attachments.entities[id].state == FETCH_STATUSES.FAILED);
}

export const selectAttachmentIdsToDelete = (state) => state.attachments.idsToDeleteOnRemote;

export const selectAttachmentsToDuplicate = (state) => Object.entries(state.attachments.attachmentsToDuplicateOnRemote)
  .map(([attachmentId, remoteId]) => ({ attachmentId, remoteId }));

export const {
  addAttachment, replaceAttachment, removeAttachment, removeManyAttachments,
  markDuplicatedOnRemote, queueToDuplicateOnRemote, setAttachment,
  updateAttachment, upsertManyAttachments } = attachmentsSlice.actions

export default attachmentsSlice.reducer;