import React from 'react';
import { translate as t } from '../../utils/translate';
import AttachmentField from './Editor/Attachment';
import { newAttachment } from '../../utils/attachment';

export default function AttachmentListField ({ attachments, onAdd, onChange, onRemove }) {
  // loop through attachments
  // display them
  // remove attachment link
  // add attachment link
  return <>
    { attachments.map(attachment => (<AttachmentField 
        key={ attachment.id } 
        attachment={ attachment }
        onChange={ onChange } 
        onRemove={ () => onRemove(attachment) } />
    ))}
    <button type="button" onClick={ () => { onAdd(newAttachment()) } }>{ t('Add attachment')}</button>
    <br />
  </>

}