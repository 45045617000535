import { useDispatch, useSelector } from 'react-redux';
import { activateEditor, MODE_CREATE, MODE_EDIT } from '../lineEditorSlice';
import { addSubline, duplicateLine, removeLine } from '../linesSlice';
import { toggleLine, selectLineExpanded } from '../interfaceSlice';
import { translate as t } from '../../../utils/translate';
import { format as f } from '../../../utils/format';
import { LINE_TYPE_REGULAR } from '../constants';


export function LineActions ({ line }) {
  const dispatch = useDispatch();
  const lineExpanded = useSelector(state => selectLineExpanded(state, line.id));
  
  return <div className="line__actions">
    <button name="edit" title={ t("Modifier cet axe") } onClick={ 
      () => dispatch(activateEditor({ line: line, mode: MODE_EDIT })) }>⋯</button>
    { line.parentId && <button name="copy" title={ t("Copier cet axe") } onClick={
      () => dispatch(duplicateLine({ lineId: line.id }))
    }>{ t('Copier') }</button> }
    {/* Linked lines can not have sublines */}
    { line.type === LINE_TYPE_REGULAR &&
    <button name="add" title={ t("Ajoute un sous-axe") } onClick={
      () => {
        dispatch(addSubline({ lineId: line.id }) ) 
        if (! lineExpanded) {
          dispatch(toggleLine({ id: line.id }))
        }
      } }>+</button> }
    <button name="delete" title={ t("Supprimer cet axe (et tous ses sous-axes)") } onClick={ () => {
      if (window.confirm(f(t("Vous êtes sur le point de supprimer l’axe {} et tous ses sous-axes. Continuer?"), line.title))) {
        dispatch(removeLine(line.id));
      } } }>x</button>
  </div>
}

export default LineActions;